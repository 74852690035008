import React from 'react';

const CustomVideo = ({ videoUrl, saveProgress, finishVideo }) => {
  const handlePause = (event) => {
    const progress = event.target.currentTime;
    saveProgress(progress);
  };

  const handleEnded = () => {
    finishVideo();
  };

  return (
    <div>
      <video
        controls
        width="100%"
        height="auto"
        onPause={handlePause}
        onEnded={handleEnded}
      >
        <track kind="captions" />
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );
};

export default CustomVideo;
